import { makeStyles } from "@mui/styles";
import {
  makeBannerProps,
  readContentValues,
  StandardPageProps,
  makeStandardPageProps,
  makeHtml,
  useUnload,
} from "../../utils/common";
import {
  useStickyContainer,
  useStickyBottom,
  useIsClient,
  useRouteInfo,
  useIsMobile,
} from "../../hooks/hooks";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import { StepperTag } from "../../interfaces/booking";
import { GvStepperTag } from "../../interfaces/giftvouchers";
import {
  checkTradingTabExpiry,
  scrollWindowToTop,
  useGVRedirectionObjectByPath,
} from "../../utils";
import React, { useEffect, useState, useRef } from "react";
import { JourneyTypes } from "../../interfaces/stepper";
import {
  FHTabbedNav,
  FHTabbedNavLink,
} from "../../components/SemanticTheme/FHTabbedNav";
import PageProperties from "../PagePropertiesTemplate";
import HeaderComponentTemplate from "../mapped-templates/experiments/HeaderTemplate";
import { BannerWidgetContainer } from "../mapped-templates/BannerWidgetContainerTemplate";
import { GVStepperComponent } from "../mapped-templates/GVStepper";
import { BookingJourneyStepperComponent } from "../mapped-templates/BookingJourneyStepper";
import BannerComponent from "../mapped-templates/BannerTemplate";
import { ThemeProvider } from "@mui/material/styles";
import SemanticTheme from "../../themes/SemanticTheme";
import { LoginComponent } from "../mapped-templates/LoginComponentTemplate";
import { RegisterComponent } from "../mapped-templates/RegisterComponentTemplate";
import { ScrollHereIfNotVisibleOnRender } from "../../components/SemanticTheme/ScrollHereIfNotVisibleOnRender";
import { Container, Grid } from "@mui/material";
import GVBookingConfirmationComponent from "../mapped-templates/GVBasket";
import {
  FHBasketComponent,
  FHBasketOverrideProps,
} from "../mapped-templates/experiments/FHBasket";
import { Hidden } from "@mui/material";
import classnames from "classnames";
import { PageHeader } from "../../components/SemanticTheme/PageHeader";
import { assignFeefoReviewAPITag } from "../../utils";
import FooterComponentTemplate from "../mapped-templates/FooterTemplate";
import ContentArea from "../../utils/ContentArea";
import {
  getGiftVoucherBasket,
  confirmActiveGvStepAction,
  getGvSettingsAction,
  redirectActionComplete,
  setIsBookingJourney,
  setLogoutOutStatus,
  setActiveStepperSetting,
  confirmActiveBookingStepAction,
  getBookingConfirmationAction,
  createLoginFromSession,
  validateXFhAuthCookie,
} from "@/store/actions";
import {
  useLoginSelector,
  useGetGiftVoucherBasketStateSelector,
  useGVBasketIdPersistedtStateSelector,
  useGvFindStepSelector,
  useGvSettingsSelector,
  useBookingIdSelector,
  useFindStepSelector,
  useSiteSettingsSelector,
  useIsXFhAuthValid,
  useBookingSummaryStateSelector,
  useGetReservationFromReferralSelector,
  useBookingSearchGuests,
  useIsAuthenticated,
} from "@/store/selectors";
import { filterXSS } from "xss";
import { hasCookie } from "cookies-next";
import { MobileTradingTab } from "@components/SemanticTheme/MobileTradingTab";
import { postBasketSummary } from "src/utils/fresh-relevance-actions";
import {
  BasicPageBannerComponentDynamicZone,
  FooterEntityResponseCollection,
  GiftVoucherBasketSummaryEntity,
  HeaderEntityResponseCollection,
  LoginRegisterPageEntityResponseCollection,
  SeoStructuredDataEntityResponse,
} from "src/graphql/generated-strapi/types";
import { IDateRange } from "src/interfaces/calendar";
import { Guests } from "src/interfaces/guests";
import {
  hasExistingBookingFunnel,
  postSSTBookingSearch,
} from "src/utils/postsst";
import moment from "moment";
import { ParsedUrlQuery } from "querystring";
import { postGABeginCheckout } from "src/utils/google-analytics";
import { updateAbsoluteLink } from "@components/Utils";
import BookingExpiry from "../mapped-templates/experiments/BookingExpiryTemplate";

interface LoginRegisterPageProps {
  rest: {
    strapiContent: {
      data: { loginRegisterPages: LoginRegisterPageEntityResponseCollection };
    };
    headerContent: {
      data: { headers: HeaderEntityResponseCollection };
    };
    footerContent: {
      data: { footers: FooterEntityResponseCollection };
    };
    seoStructuredData: {
      data: {
        seoStructuredData: SeoStructuredDataEntityResponse;
      };
    };
    experiments?: { [key in string]: string | boolean };
    hideSubscribeSticky?: boolean;
    queryData?: ParsedUrlQuery;
    headers?: any;
  };
}
enum AuthTab {
  Login = "Login",
  Register = "Register",
}

enum SummaryType {
  GVBasketSummary = "GV_Basket_Summary",
  BookingSummary = "Booking_Summary",
}

type CmsData = ReturnType<typeof makeCmsData>;
const makeCmsData = (content: any) => ({
  ...readContentValues(content, [
    "HeaderRef",
    "FooterRef",
    "GiftVoucherBasketSummary",
    "isExtrasOnlyPurchaseJourney",
    "IsPurchaseJourneyPage",
    "SummaryTypeString",
    "BookingSummaryRef",
    "PrimaryTab",
    "Basket",
  ]),
});

const useStyles = () =>
  makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    tabContent: {
      margin: 0,
    },
    navigationArrowsContainer: {
      paddingBottom: theme.spacing(2),
    },
    container: {
      marginTop: theme.spacing(4),
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(0),
      },
    },
    mobileButtons: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
      alignItems: "center",
      "& .MuiPaper-root": {
        backgroundColor: "transparent",
        boxShadow: "none",
      },
    },
    pageHeader: {
      [theme.breakpoints.down("sm")]: {
        display: "block",
        "& > .MuiGrid-root + .MuiGrid-root": {
          marginTop: theme.spacing(1),
          borderTop: `1px solid ${theme.palette.divider}`,
          paddingTop: theme.spacing(1),
          "& .MuiGrid-root": {
            flex: "0 1 100%",
            maxWidth: "100%",
          },
        },
      },
    },
    bottomStickyContainer: {
      position: "absolute",
      bottom: 0,
      top: "auto",
      width: "calc(100% - 30px)",
      [theme.breakpoints.up("lg")]: {
        width: 290,
      },
    },
    stickyContainer: {
      position: "fixed",
      zIndex: 9,
      top: 0,
      width: "calc(25% - 30px)",
      [theme.breakpoints.up("lg")]: {
        width: 290,
      },
    },
    basketContainer: {
      position: "sticky",
      top: 0,
      paddingTop: 30,
      width: "100%",
      [theme.breakpoints.up("lg")]: {
        width: 292,
      },
    },
    bookingJourneyBgColor: {
      backgroundColor: theme.palette.custom.experimentChangeBgColourToGrey,
    },
    pageContent: {
      marginTop: 20,
    },
  }))();

const LoginRegisterPageComponent = (props: LoginRegisterPageProps) => {
  const seoStructuredData =
    props?.rest?.seoStructuredData?.data?.seoStructuredData?.data?.attributes;
  const loginRegisterPagesCmsData =
    props?.rest?.strapiContent?.data?.loginRegisterPages?.data?.[0]?.attributes;
  const cmsData: CmsData = makeCmsData(loginRegisterPagesCmsData);
  const bannerProps = makeBannerProps(loginRegisterPagesCmsData || {});
  const tradingTabProps =
    loginRegisterPagesCmsData?.TradingTab?.data?.attributes;
  const bannerComponentRef = loginRegisterPagesCmsData?.BannerComponent;
  const isBookingWidget = !!Object.keys(bannerComponentRef)?.length;
  const headerProps =
    props?.rest?.headerContent?.data?.headers?.data?.[0]?.attributes;
  const footerProps =
    props?.rest?.footerContent?.data?.footers?.data?.[0]?.attributes;
  const stickyBannerProps =
    loginRegisterPagesCmsData?.StickyBanner?.data?.attributes || null;
  const pageProperties: StandardPageProps = makeStandardPageProps(
    loginRegisterPagesCmsData?.seo || {},
  );
  pageProperties["ShowSubscribeModal"] =
    loginRegisterPagesCmsData?.ShowSubscribeModal;
  assignFeefoReviewAPITag(pageProperties, loginRegisterPagesCmsData);

  const isExtrasOnlyPurchaseJourney =
    cmsData?.isExtrasOnlyPurchaseJourney || false;

  if (stickyBannerProps && headerProps)
    headerProps["stickyBannerProps"] = stickyBannerProps;
  if (props?.rest?.headers) pageProperties["nextHeaders"] = props.rest.headers;
  if (!!seoStructuredData)
    pageProperties["seoStructuredData"] = seoStructuredData;

  const classes = useStyles();
  const history = useRouter();
  const isClient = useIsClient();
  const { data: loginData, loggedOutRecently: userLoggedOutRecently } =
    useLoginSelector();
  const { basketId } = useGVBasketIdPersistedtStateSelector();
  const dispatch = useDispatch();
  const paymentStep = useFindStepSelector(StepperTag.payment);
  const gvPaymentStep = useGvFindStepSelector(GvStepperTag.payment);
  const gvLoginStep = useGvFindStepSelector(GvStepperTag.login);
  const regLoginStep = useFindStepSelector(StepperTag.login);
  const bookingId = useBookingIdSelector();
  const { activeStepIndex } = useGVRedirectionObjectByPath(history?.asPath);
  const { loginNextPageUrl, activeBookingStepIndex } =
    useSiteSettingsSelector();
  const { isValidXFhAuthCookie } = useIsXFhAuthValid();
  const gvContentPage = useGvFindStepSelector(GvStepperTag.select);
  const stickyRef = useRef<HTMLHRElement>(null);
  const revealStickyRef = useRef<HTMLHRElement>(null);
  const stickyState = useStickyContainer(stickyRef, revealStickyRef);
  const stickyBottomRef = useRef<HTMLHRElement>(null);
  const stickyBottomState = useStickyBottom(stickyBottomRef);
  const { cabin: newBookingCabin, confirmation } =
    useBookingSummaryStateSelector();
  const { latestRoute: currentPageUrl, routeLoaded } = useRouteInfo();
  const { referrer } = useGetReservationFromReferralSelector();
  const guests = useBookingSearchGuests();
  const [
    isBasketSummaryPushedToDataLayer,
    setIsBasketSummaryPushedToDataLayer,
  ] = useState(false);
  const isMobile = useIsMobile();
  const experiments = props?.rest?.experiments;

  // effect written for FR
  useEffect(() => {
    if (!!newBookingCabin) {
      !isBasketSummaryPushedToDataLayer && postBasketSummary(newBookingCabin);
      setIsBasketSummaryPushedToDataLayer(true);
    }
  }, [newBookingCabin, isBasketSummaryPushedToDataLayer]);

  useEffect(() => {
    if (
      !currentPageUrl ||
      !routeLoaded ||
      cmsData?.IsPurchaseJourneyPage ||
      !newBookingCabin
    ) {
      return;
    }

    const fetchBookingFunnelData = async () => {
      const hasBookingFunnel = await hasExistingBookingFunnel(currentPageUrl);
      if (!hasBookingFunnel) {
        const locationIds = [newBookingCabin?.locationId];
        const dateRange: IDateRange = {
          startDateISO:
            newBookingCabin?.reservationStartDate ??
            moment(newBookingCabin?.reservationStartDate).format("YYYY-MM-DD"),
          endDateISO:
            newBookingCabin?.reservationEndDate ??
            moment(newBookingCabin?.reservationEndDate).format("YYYY-MM-DD"),
        };
        const guests: Guests = {
          adults: 0,
          bedrooms: newBookingCabin?.noOfBedrooms ?? 0,
          children: 0,
          infants: 0,
          pets: newBookingCabin?.noOfPetsBooked ?? 0,
          guestTotal: 0,
        };
        const dda = newBookingCabin?.disabledAccess;
        const cabinTypeId = newBookingCabin?.cabinTypeId;

        postSSTBookingSearch(
          locationIds,
          dateRange,
          guests,
          dda,
          currentPageUrl,
          referrer,
          cabinTypeId,
        );
      }
    };

    fetchBookingFunnelData().catch(console.error);
  }, [newBookingCabin, currentPageUrl, routeLoaded]);

  useEffect(() => {
    if (Number(basketId || 0) > 0) {
      dispatch(
        getGiftVoucherBasket({
          basketId: Number(basketId || 0),
        }),
      );
    }
  }, [basketId]);

  useEffect(() => {
    scrollWindowToTop();
  }, []);

  const { data: giftVoucher, loading: isLoading } =
    useGetGiftVoucherBasketStateSelector();
  const { gvSettings } = useGvSettingsSelector();

  useEffect(() => {
    if (!gvSettings) dispatch(getGvSettingsAction());
    dispatch(redirectActionComplete());
  }, [gvSettings]);

  useEffect(() => {
    if (gvContentPage && gvSettings) {
      if (giftVoucher?.giftVoucherBasket?.length === 0) {
        history.push(filterXSS(gvContentPage?.url) || "");
      }
    }
  }, [giftVoucher, gvSettings]);

  useEffect(() => {
    dispatch(setIsBookingJourney(cmsData?.IsPurchaseJourneyPage));
  }, [cmsData?.IsPurchaseJourneyPage]);

  useEffect(() => {
    dispatch(createLoginFromSession());
    return () => {
      dispatch(setLogoutOutStatus(false));
    };
  }, []);

  useUnload(() => {
    dispatch(setLogoutOutStatus(false));
  });

  useEffect(() => {
    const isUserLoggedIn = !!loginData && !!loginData?.token;
    if (isUserLoggedIn) {
      // check rememberlogin cookie is existing
      const isSignedInDeviceIdCookieExists = hasCookie("SignedInDeviceId");
      let returnUrl = filterXSS(
        history.asPath.split("?returnUrl=")?.[1],
      ) as string;
      if (returnUrl && returnUrl.includes("|")) {
        returnUrl = returnUrl.replaceAll("|", "&");
      }
      const nextPageUrl =
        returnUrl ||
        (cmsData?.IsPurchaseJourneyPage &&
        cmsData?.SummaryTypeString !== SummaryType.GVBasketSummary
          ? paymentStep?.url
          : cmsData?.SummaryTypeString === SummaryType.GVBasketSummary
            ? gvPaymentStep?.url
            : loginNextPageUrl);
      if (cmsData?.IsPurchaseJourneyPage) {
        if (cmsData?.SummaryTypeString === SummaryType.GVBasketSummary) {
          dispatch(
            setActiveStepperSetting({
              activeJourneyStep: activeStepIndex + 1,
              activeJourney: JourneyTypes.gvJourney,
            }),
          );
          dispatch(confirmActiveGvStepAction());
        } else {
          dispatch(
            setActiveStepperSetting({
              activeJourneyStep: activeBookingStepIndex + 1,
              activeJourney: JourneyTypes.bookingJourney,
            }),
          );
          dispatch(confirmActiveBookingStepAction());
        }
      }

      // redirect user to login page
      if (isSignedInDeviceIdCookieExists) {
        dispatch(validateXFhAuthCookie(loginData.customer.id));
        // if (isValidXFhAuthCookie && !!nextPageUrl) {
        if (!!nextPageUrl) {
          window.location.href = nextPageUrl;
        }
      } else if (!!nextPageUrl) {
        window.location.href = nextPageUrl;
      }
    }
  }, [loginData, activeBookingStepIndex, isValidXFhAuthCookie]);

  useEffect(() => {
    if (!bookingId) return;
    dispatch(getBookingConfirmationAction({ bookingId }));
  }, [bookingId]);
  const { loaded: loadingComplete, isLoggedInOnExtrasPage } =
    useSiteSettingsSelector();
  useEffect(() => {
    if (
      cmsData?.IsPurchaseJourneyPage &&
      confirmation &&
      !loginData &&
      loadingComplete
    ) {
      postGABeginCheckout(confirmation, guests);
    }
  }, [loadingComplete]);

  const showDefaultTab = (
    authTab: AuthTab,
    showAfterLogout: boolean,
    showDefault: boolean,
  ) => {
    const cmsDefaultTab = cmsData?.PrimaryTab;
    if (!!cmsDefaultTab) {
      return userLoggedOutRecently
        ? showAfterLogout
        : cmsDefaultTab === authTab;
    } else {
      return userLoggedOutRecently ? showAfterLogout : showDefault;
    }
  };

  const makeInitialTabs = (): FHTabbedNavLink[] => [
    {
      primaryLabel: AuthTab.Register,
      active: showDefaultTab(AuthTab.Register, false, true),
      onTabClick: () => handleTabChange(0),
    },
    {
      primaryLabel: AuthTab.Login,
      active: showDefaultTab(AuthTab.Login, true, false),
      onTabClick: () => handleTabChange(1),
    },
  ];

  const [tabs, setTabs] = useState<FHTabbedNavLink[]>(makeInitialTabs);

  const handleTabChange = (index: number) => {
    setTabs(
      tabs.map((tab, i) =>
        index === i
          ? {
              ...tab,
              active: true,
            }
          : {
              ...tab,
              active: false,
            },
      ),
    );
  };

  const getTab = () => {
    const activeTabLabel = tabs.find((tab) => tab.active)?.primaryLabel;

    const overrideContinueCTALabel = cmsData?.IsPurchaseJourneyPage
      ? loginRegisterPagesCmsData?.PreBookingContinueCTALabel
      : undefined;

    switch (activeTabLabel) {
      case AuthTab.Login:
        return (
          <LoginComponent
            content={loginRegisterPagesCmsData}
            showLogoutMessage={userLoggedOutRecently}
            overrideContinueCTALabel={overrideContinueCTALabel}
          />
        );
      case AuthTab.Register:
        return (
          <RegisterComponent
            content={loginRegisterPagesCmsData}
            overrideContinueCTALabel={overrideContinueCTALabel}
            signUpWordingRebook={!!experiments?.signUpWordingRebook}
          />
        );
      default:
        return null;
    }
  };

  const parsedText = (text?: string) => {
    return text ? (
      <div dangerouslySetInnerHTML={makeHtml(text as string)} />
    ) : undefined;
  };

  let nextHeaders: any;
  if (!!props?.rest?.headers) {
    nextHeaders = JSON.parse(props?.rest?.headers);
  }

  const isAuthenticated = useIsAuthenticated();
  const basketSummaryImprovements = !!experiments?.basketSummaryImprovements;
  const bookingExpiredModelProps =
    loginRegisterPagesCmsData?.Basket?.data?.attributes?.BookingExpiredModal;

  return (
    <>
      <PageProperties {...pageProperties} />
      <BookingExpiry {...bookingExpiredModelProps} />
      {bannerProps && (
        <BannerComponent
          {...bannerProps}
          isBookingWidget={isBookingWidget}
          queryData={props?.rest?.queryData}
          additionalProps={experiments}
        >
          {headerProps && (
            <HeaderComponentTemplate
              content={headerProps}
              additionalProps={experiments}
              hideSubscribeSticky={props?.rest?.hideSubscribeSticky}
            />
          )}
          {tradingTabProps?.TradingTabInMobile &&
            checkTradingTabExpiry(bannerProps) && (
              <MobileTradingTab
                tradingTab={{
                  url: updateAbsoluteLink(
                    bannerProps?.tradingTabURL?.data?.attributes?.PageRoute,
                  ),
                  tradingTabInMobile: parsedText(
                    tradingTabProps?.TradingTabInMobile,
                  ),
                }}
                isBookingWidget={isBookingWidget}
                makeOfferBannerUnclicable={
                  !!experiments?.makeOfferBannerUnclicable
                }
              />
            )}
          {loginRegisterPagesCmsData?.PageRoute === regLoginStep?.url && (
            <BookingJourneyStepperComponent hideMobileNav={true} />
          )}
          {loginRegisterPagesCmsData?.PageRoute === gvLoginStep?.url && (
            <GVStepperComponent isGVjourney />
          )}
          {!cmsData?.IsPurchaseJourneyPage && !!bannerComponentRef && (
            <BannerWidgetContainer>
              <ContentArea
                content={
                  bannerComponentRef as BasicPageBannerComponentDynamicZone[]
                }
                additionalProps={experiments}
              />
            </BannerWidgetContainer>
          )}
        </BannerComponent>
      )}
      <ThemeProvider theme={SemanticTheme}>
        <FHTabbedNav links={tabs} mobileText={""} maxWidth={970} />
      </ThemeProvider>
      <Container className={classes.pageContent}>
        <Grid item xs={12}>
          <ScrollHereIfNotVisibleOnRender>
            <PageHeader
              subtitle={cmsData?.PageDescription ?? ""}
              className={classes.pageHeader}
              isSticky
              mobileMenuButton={
                !!cmsData?.Basket?.data ? (
                  <div className={classes.mobileButtons}>
                    <FHBasketComponent
                      attributes={loginRegisterPagesCmsData?.Basket?.data}
                      overrideProps={
                        {
                          isPostBookingJourney:
                            !cmsData?.IsPurchaseJourneyPage || false,
                          isExtrasOnlyPurchaseJourney:
                            isExtrasOnlyPurchaseJourney,
                          hideBasketCount: !!experiments?.removeBasketCount,
                          largerTotalPrice: !!experiments?.largerTotalPrice,
                          experiments: experiments,
                        } as FHBasketOverrideProps
                      }
                    />
                  </div>
                ) : cmsData?.IsPurchaseJourneyPage &&
                  cmsData?.BookingSummaryRef &&
                  cmsData?.SummaryTypeString === SummaryType.BookingSummary ? (
                  <GVBookingConfirmationComponent
                    attributes={
                      loginRegisterPagesCmsData?.BookingSummaryRef
                        ?.data as GiftVoucherBasketSummaryEntity
                    }
                  />
                ) : undefined
              }
            />
          </ScrollHereIfNotVisibleOnRender>
        </Grid>
        <Grid container spacing={3}>
          {isClient && (
            <Grid
              item
              md={
                cmsData?.IsPurchaseJourneyPage ||
                cmsData?.SummaryTypeString === SummaryType.GVBasketSummary
                  ? 9
                  : 12
              }
              xs={12}
            >
              <div className={classes.tabContent}>{getTab()}</div>
            </Grid>
          )}
          {!!cmsData?.Basket?.data ? (
            <Hidden mdDown>
              <Grid
                item
                md={3}
                xs={12}
                ref={stickyRef}
                style={{
                  position: "relative",
                }}
              >
                <div
                  className={classnames({
                    [classes.basketContainer]: basketSummaryImprovements,
                    [classes.stickyContainer]: stickyState,
                    [classes.bottomStickyContainer]: stickyBottomState,
                  })}
                >
                  <FHBasketComponent
                    attributes={loginRegisterPagesCmsData?.Basket?.data}
                    overrideProps={
                      {
                        isPostBookingJourney:
                          !cmsData?.IsPurchaseJourneyPage || false,
                        isExtrasOnlyPurchaseJourney:
                          isExtrasOnlyPurchaseJourney,
                        hideBasketCount: !!experiments?.removeBasketCount,
                        largerTotalPrice: !!experiments?.largerTotalPrice,
                        experiments: experiments,
                      } as FHBasketOverrideProps
                    }
                  />
                </div>
              </Grid>
            </Hidden>
          ) : (
            cmsData?.IsPurchaseJourneyPage &&
            cmsData?.BookingSummaryRef &&
            cmsData?.SummaryTypeString === SummaryType.BookingSummary && (
              <GVBookingConfirmationComponent
                attributes={
                  loginRegisterPagesCmsData?.BookingSummaryRef
                    ?.data as GiftVoucherBasketSummaryEntity
                }
              />
            )
          )}

          {cmsData?.IsPurchaseJourneyPage &&
            cmsData?.BookingSummaryRef &&
            cmsData?.SummaryTypeString === SummaryType.GVBasketSummary && (
              <Grid item md={3} xs={12}>
                <GVBookingConfirmationComponent
                  attributes={
                    loginRegisterPagesCmsData?.BookingSummaryRef
                      ?.data as GiftVoucherBasketSummaryEntity
                  }
                />
              </Grid>
            )}
        </Grid>
      </Container>
      <div ref={stickyBottomRef}>
        <div ref={revealStickyRef}>
          {footerProps && (
            <FooterComponentTemplate
              content={{
                ...footerProps,
                experiments,
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default LoginRegisterPageComponent;
